import React from "react";
import Footer from "@/components/footer";
import Layout from "@/components/layout";
import PageBanner from "@/components/page-banner";
import SearchContextProvider from "@/context/search-context";
import MenuContextProvider from "@/context/menu-context";
import HeaderOne from "@/components/header-one";
import PlainContent from "@/components/plain-content";
import { termsData } from "@/data";

const TermsPage = () => {
    const content = `Terms of Service Coming Soon`;
    return (
        <MenuContextProvider>
            <SearchContextProvider>
                <Layout PageTitle="Terms Page">
                    <HeaderOne />
                    <PageBanner title="Terms of Service" name="Terms of Service" />
                    <PlainContent title="" content={termsData} />
                    <Footer />
                </Layout>
            </SearchContextProvider>
        </MenuContextProvider>
    );
};

export default TermsPage;
