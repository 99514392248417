import React from "react";

const PlainContent = ({title, content}) => {
  return (
    <section className="commonSection">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <h2 className="sec_title text-center">{title}</h2>
            <p className="sec_desc">
              <div dangerouslySetInnerHTML={{__html:content}} />
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default PlainContent;
